// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PeriodOfTime_container__Kn27T {
    margin-bottom: 20px;
}

.PeriodOfTime_partOfDay__M7nFh {
    color: var(--tg-theme-hint-color);
}

.PeriodOfTime_freeTimeContainer__qx2zp {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 9px;
    padding-top: 12px;
}

.PeriodOfTime_timeLabel__mKwsm { 
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: var(--tg-theme-section-bg-color);
    /* background-color: gray; */
    color: var(--tg-theme-text-color);
    max-width: 82px;
    height: 34px;
}

.PeriodOfTime_radioInput__OurML {
    display: none;
}

.PeriodOfTime_radioInput__OurML:checked + .PeriodOfTime_timeLabel__mKwsm {
    background-color: var(--tg-theme-button-color);
    /* background-color: blue; */
    color: var(--tg-theme-button-text-color);
}

/* .timeLabel {
    display: flex;
    align-items: center;
} */`, "",{"version":3,"sources":["webpack://./src/components/selectTime/freeTime/periodOfTime/PeriodOfTime.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,QAAQ;IACR,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,kDAAkD;IAClD,4BAA4B;IAC5B,iCAAiC;IACjC,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,8CAA8C;IAC9C,4BAA4B;IAC5B,wCAAwC;AAC5C;;AAEA;;;GAGG","sourcesContent":[".container {\n    margin-bottom: 20px;\n}\n\n.partOfDay {\n    color: var(--tg-theme-hint-color);\n}\n\n.freeTimeContainer {\n    display: grid;\n    grid-template-columns: repeat(4, 1fr);\n    gap: 9px;\n    padding-top: 12px;\n}\n\n.timeLabel { \n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 8px;\n    background-color: var(--tg-theme-section-bg-color);\n    /* background-color: gray; */\n    color: var(--tg-theme-text-color);\n    max-width: 82px;\n    height: 34px;\n}\n\n.radioInput {\n    display: none;\n}\n\n.radioInput:checked + .timeLabel {\n    background-color: var(--tg-theme-button-color);\n    /* background-color: blue; */\n    color: var(--tg-theme-button-text-color);\n}\n\n/* .timeLabel {\n    display: flex;\n    align-items: center;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PeriodOfTime_container__Kn27T`,
	"partOfDay": `PeriodOfTime_partOfDay__M7nFh`,
	"freeTimeContainer": `PeriodOfTime_freeTimeContainer__qx2zp`,
	"timeLabel": `PeriodOfTime_timeLabel__mKwsm`,
	"radioInput": `PeriodOfTime_radioInput__OurML`
};
export default ___CSS_LOADER_EXPORT___;
